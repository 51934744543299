.addButton {
  color: #aaa;
  float: right;
  font-size: 35px;
  margin: 10px;
  padding: 0 7px;
  font-size: 35px;
  font-weight: bold;
}

.addButton:hover,
.addButton:focus {
  color: #de3c4b; /* rusty red */
  text-decoration: none;
  cursor: pointer;
}

.closeButton {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.closeButton:hover,
.closeButton:focus {
  color: #de3c4b; /* rusty red */
  text-decoration: none;
  cursor: pointer;
}

.editButton,
.deleteButton,
.saveButton {
  margin-right: 4px;
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.editButton:hover,
.editButton:focus,
.deleteButton:hover,
.deleteButton:focus,
.saveButton:hover,
.saveButton:focus {
  color: #de3c4b; /* rusty red */
  text-decoration: none;
  cursor: pointer;
}
