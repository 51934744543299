/* Below is for the MODAL */
.modalBackground {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modalContent {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border-radius: 12px;
  box-shadow: -5px 5px 30px 0px rgb(0, 0, 0);
  width: 80%; /* Could be more or less, depending on screen size */
}

.blogTitleEdit {
  display: block;
  width: 95%;
}

.blogContentEdit {
  display: block;
  width: 95%;
  height: 300px;
  overflow-y: scroll;
}

.blogDateEdit {
}
