.latestContainer {
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.about {
  font-size: 18px;
}

.link {
  float: right;
  margin-left: 7px;
}
