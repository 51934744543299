.box {
  border: 3px solid #de3c4b; /* rusty red */
  border-radius: 12px;
  text-align: center;
  width: 88px;
  height: 74px;
}

.month {
  color: white;
  background-color: #de3c4b; /* rusty red */
  border-radius: 9px 9px 0 0;
  margin: 0;
  width: 88px;
  font-size: 40px;
}

.dayYearCombo {
  margin-top: 2px;
  font-size: 18px;
}
