.portfolioEntryCard {
  width: 250px;
  height: 330px;
  padding: 15px;
  margin: 20px;
  cursor: pointer;
  /* Neumorphism */
  border-radius: 15px;
  background: #fbf5f3;
  box-shadow: 8px 8px 16px #d5d0cf, -8px -8px 16px #ffffff;
}

.portfolioEntryCard:hover {
  transform: scale(1.03, 1.03);
  /* Neumorphism */
  border-radius: 15px;
  background: #fbf5f3;
  box-shadow: 16px 16px 30px #d5d0cf, -16px -16px 30px #ffffff;
}

/* ---------------------------------------- */

.portfolioEntryImage {
  display: block;
  margin: auto;
  height: 160px;
  max-width: 250px;
  max-height: 250px;
  border-radius: 8px;
}

.portfolioEntryTitle {
  margin-top: 10px;
  margin-bottom: 0;
  max-width: 250px;
  border-bottom: 2px solid #de3c4b; /* rusty red */
}

.portfolioEntryDescription {
  width: 250px;
  height: 110px;
  max-width: 250px;
  max-height: 110px;
  overflow: hidden;
}

/* ---------------------------------------- */
/* Homepage Version */
/* ---------------------------------------- */

.portfolioEntryCardHome {
  width: 250px;
  height: 215px;
  padding: 15px;
  margin: 20px;
  cursor: pointer;
  /* Neumorphism */
  border-radius: 15px;
  background: #fbf5f3;
  box-shadow: 8px 8px 16px #d5d0cf, -8px -8px 16px #ffffff;
}

.portfolioEntryCardHome:hover {
  transform: scale(1.03, 1.03);
  /* Neumorphism */
  border-radius: 15px;
  background: #fbf5f3;
  box-shadow: 16px 16px 30px #d5d0cf, -16px -16px 30px #ffffff;
}

.portfolioEntryTitleHome {
  margin: 0 0 20px 0;
  max-width: 250px;
  border-bottom: 2px solid #de3c4b; /* rusty red */
}
