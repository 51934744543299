body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif;
  background-image: url("./assets/Confetti-Doodles-Yellow.svg");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

hr {
  border: 5px solid #e28413; /* fulvous */
  border-radius: 5px;
}

a {
  text-decoration: none;
  color: #c42847;
}

a:hover {
  color: #e28413; /* french raspberry */
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 400ms ease-in;
}

.fade-exit {
  opacity: 0;
  z-index: 0;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 10ms ease-out;
}

.welcome-exit {
  opacity: 1;
}
.welcome-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}

.modal-enter {
  opacity: 0;
}
.modal-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.modal-exit {
  opacity: 1;
}
.modal-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
