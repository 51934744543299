.modalBackground {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modalContent {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border-radius: 12px;
  box-shadow: -5px 5px 30px 0px rgb(0, 0, 0);
  width: 80%; /* Could be more or less, depending on screen size */
  max-width: 800px;
}

.portfolioImageContainer {
  height: 500px;
  max-height: 500px;
}

@media screen and (max-width: 400px) {
  .portfolioImageContainer {
    height: 220px;
    max-height: 250;
  }
}

.portfolioEntryImage {
  max-width: 95%;
  max-height: 100%;
  border-radius: 8px;
}

.portfolioEntryTitle {
  margin: 0;
  font-size: 40px;
}

.portfolioEntryDescription {
  max-width: 90%;
  overflow: auto;
}

.portfolioImageEdit,
.portfolioTitleEdit {
  display: block;
  width: 95%;
}

.portfolioDescriptionEdit {
  display: block;
  width: 95%;
  height: 300px;
  overflow-y: scroll;
}

.modalWidgetPlacement {
  display: block;
}
