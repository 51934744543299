.title {
  text-align: center;
  font-family: "Kumbh Sans", sans-serif;
  font-size: 3.5em;
  margin-top: 10px;
  margin-bottom: 10px;
}

.formContainer {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formElement {
  margin: 7px;
  width: 200px;
  max-width: 200px;
}

.formButton {
  margin: 7px;
  width: 60px;
  max-width: 60px;
}
