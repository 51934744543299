.welcomeContainer {
  z-index: 2;
  background-color: white;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #e28413;
  font-family: "Share Tech Mono", monospace;
}

.welcomeContent {
  margin-top: 25%;
  text-align: center;
}

.welcomeMessage {
  color: #fbf5f3;

  font-size: 85px;
  margin-top: 20px;
}
