.blogEntryCard {
  width: 90%;
  height: 80px;
  max-width: 760px;
  max-height: 80px;
  padding: 10px;
  margin: 15px 15px;
  overflow: hidden;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  /* Neumorphism */
  border-radius: 15px;
  background: #fbf5f3;
  box-shadow: 8px 8px 16px #d5d0cf, -8px -8px 16px #ffffff;
}

.blogEntryCard:hover {
  transform: scale(1.03, 1.03);
  /* Neumorphism */
  border-radius: 15px;
  background: #fbf5f3;
  box-shadow: 16px 16px 30px #d5d0cf, -16px -16px 30px #ffffff;
}

.blogEntryInfo {
  width: 100%;
  max-height: 80px;
  margin-left: 5px;
}

@media screen and (max-width: 600px) {
  .blogEntryInfo {
    width: 100%;
    max-width: 70%;
    max-height: 80px;
    margin-left: 5px;
  }
}

.blogEntryTitle {
  margin: 0;
  padding: 2px 0;
  font-size: 30px;
  border-bottom: 2px solid #de3c4b; /* rusty red */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blogEntryContent {
  height: 40px;
  max-height: 40px;
  overflow: hidden;
  margin: 0;
}

/* Homepage Version */

.blogEntryCardHome {
  width: 250px;
  height: 215px;
  padding: 15px;
  margin: 20px;
  overflow: hidden;
  cursor: pointer;
  align-items: center;
  /* Neumorphism */
  border-radius: 15px;
  background: #fbf5f3;
  box-shadow: 8px 8px 16px #d5d0cf, -8px -8px 16px #ffffff;
}

/* Scale up the card */
.blogEntryCardHome:hover {
  transform: scale(1.03, 1.03);
  /* Neumorphism */
  border-radius: 15px;
  background: #fbf5f3;
  box-shadow: 16px 16px 30px #d5d0cf, -16px -16px 30px #ffffff;
}

.blogEntryTitleHome {
  margin: 0;
  padding: 2px 0;
  border-bottom: 2px solid #de3c4b; /* rusty red */
}

.blogEntryDateHome {
  margin: 0 0 10px 0;
  color: gray;
  font-style: italic;
  font-size: 16px;
}

.blogEntryContentHome {
  height: 152px;
  max-height: 152px;
  overflow: hidden;
  margin: 0;
}
