.title {
  font-family: "Kumbh Sans", sans-serif;
  font-size: 3.5em;
  display: inline;
}

.subTitle {
  font-family: "Kumbh Sans", sans-serif;
  font-size: 2.5em;
  margin-top: 10px;
  margin-bottom: 10px;
}

.backgroundCard {
  margin: auto;
  margin-bottom: 15px;
  max-width: 800px;
  border-radius: 12px;
  border: 4px solid #e28413; /* fulvous */
  padding: 15px;
  background-color: #fbf5f3; /* snow */
}
