.blogList {
  width: 100%;
  max-width: 800px;
  list-style: none;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 0;
}

.blogListItem {
  width: 100%;
}
