.navContainer {
  margin: auto;
  max-width: 830px;
  max-width: 300px;
}

.navList {
  z-index: 1;
  list-style: none;
  text-align: center;
  padding: 10px 0;
  margin: 0;
  background-color: #fbf5f3; /* snow */
  border-radius: 0 0 12px 12px;
  font-size: 20px;
  display: inline-flex;
  justify-content: center;
  width: 100%;
  position: fixed;
  top: 0px;
  left: -4px;
  border-bottom: 4px solid #e28413; /* fulvous */
  border-left: 4px solid #e28413; /* fulvous */
  border-right: 4px solid #e28413; /* fulvous */
}

.navItem {
  text-decoration: none;
  display: inline;
  margin: 0 12px;
  color: black;
}

.navItem:hover {
  color: #c42847; /* french raspberry */
}

.currentPage {
  text-decoration: underline #de3c4b; /* rusty red */
  color: #c42847;
}
